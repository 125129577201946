import React, { useState } from "react";
import { useMutation } from "react-query";
import { Alert, Col, Row, Table, FormGroup, Input } from "reactstrap";
import { Receipt } from "react-bootstrap-icons";
import IconButton from "../../../components/Button";
import { sendTransactionReceipt } from "../../../query/queryFunctions";
import { useAuthContext } from "../../../context/authContext";
import { toast } from "react-toastify";
import TableRow from "./Row";

const TransactionHistory = ({
  customer,
  charges,
  toggleRefundModal,
  setRefundData,
  setRefunds,
  toggleRefundDetailsModal,
}) => {
  const { user } = useAuthContext();
  const [searchText, setSearchText] = useState("");
  const [selectedTransactions, setSelectedTransactions] = useState([]);

  const handleToggleRefundModal = (charge) => {
    toggleRefundModal();
    const data = {
      chargeId: charge.id,
      amount:
        Math.round(
          (charge.amount / 100 -
            charge.amount_refunded / 100 +
            Number.EPSILON) *
            100
        ) / 100,
      actualAmount: charge.amount / 100 - charge.amount_refunded / 100,
      reason: "",
    };
    setRefundData(data);
  };

  const handleToggleRefundDetailsModal = (refunds) => {
    toggleRefundDetailsModal();
    setRefunds(refunds);
  };

  const getFilteredTransactions = () => {
    let filteredTransactions = [];
    filteredTransactions =
      charges &&
      charges?.filter(
        (charge) =>
          charge.id.slice(20).includes(searchText) ||
          charge.payment_intent.includes(searchText)
      );
    return filteredTransactions.length > 0
      ? filteredTransactions
      : charges?.data;
  };

  const mutation = useMutation(sendTransactionReceipt, {
    onSuccess: (response) => {
      toast.success(response?.message);
      setSelectedTransactions("");
    },
  });

  const { mutate, isLoading, isSuccess } = mutation;

  const handleRecieptEmailModal = (charge) => {
    if (!charge || !charge.id) {
      console.error("Invalid charge object provided");
      return;
    }
      const transactionsDetails = {
      id: charge.id,
      amount: (charge.amount / 100).toFixed(2), 
      created: new Date(charge.created * 1000).toLocaleDateString(), 
      receipt_url: charge.receipt_url,  
      description: charge.description,
    };

    const receiverEmail = charge.receipt_email;
    const payload = {
      brand: user?.data.brand, 
      brandLogo: user?.data.logo, 
      receiver: receiverEmail, 
      transactions: [transactionsDetails], 
    };
    mutate(payload);
  };

  const handleSendTransactionReceipt = () => {
    const payload = {
      brand: user?.data.brand,
      brandLogo: user?.data.logo,
      receiver: customer,
      transactions: selectedTransactions,
    };
    mutate(payload);
  };

  return (
    <Row>
      <Col>
        <FormGroup className="mb-3">
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search a transaction"
          />
        </FormGroup>
        {selectedTransactions?.length > 0 && (
          <FormGroup className="float-right">
            <IconButton
              title="Send Receipt"
              className="btn-sm"
              color="primary"
              disabled={isLoading}
              icon={<Receipt className="mr-2" />}
              onClick={handleSendTransactionReceipt}
            />
          </FormGroup>
        )}
        <Table responsive bordered hover>
          <thead>
            <tr className="text-center">
              <th></th>
              <th>Card</th>
              <th>Transaction ID</th>
              <th>Invoice #</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Paid Date</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {charges?.length > 0 ? (
              getFilteredTransactions() &&
              getFilteredTransactions().map((charge, index) => (
                <TableRow
                  key={index}
                  charge={charge}
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  handleToggleRefundModal={handleToggleRefundModal}
                  handleToggleRefundDetailsModal={
                    handleToggleRefundDetailsModal
                  }
                  handleRecieptEmailModal={handleRecieptEmailModal}
                  selectedTransactions={selectedTransactions}
                  setSelectedTransactions={setSelectedTransactions}
                />
              ))
            ) : (
              <tr>
                <td colSpan={9}>
                  <Alert color="info">
                    This customer has no transaction yet.
                  </Alert>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default TransactionHistory;
