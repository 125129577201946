import React, { useState } from "react";
import { useQuery } from "react-query";
import { Spinner } from "reactstrap";
import Customers from "../../pages/Customers";
import { keys } from "../../query/keys";
import { getAllCustomers } from "../../query/queryFunctions";

const CustomersContainer = () => {
  const [searchText, setSearchText] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [previousPage, setPreviousPage] = useState("");
  const [page, setPage] = useState(1);

  const {
    isLoading,
    refetch,
    data: allCustomersList,
  } = useQuery([keys.customers, nextPage, previousPage], () =>
    getAllCustomers({ nextPage, previousPage })
  );

  if (isLoading) {
    return (
      <div className="text-center mt-5">
        <Spinner color="primary" />
      </div>
    );
  }

  const getFilteredCustomers = () => {
    let filteredCustomers = [];
    filteredCustomers =
      allCustomersList?.data &&
      allCustomersList?.data?.filter(
        (customer) =>
          customer.name.toLowerCase().includes(searchText.toLowerCase()) ||
          customer.email.toLowerCase().includes(searchText.toLowerCase())
      );
    return filteredCustomers?.length > 0 ? filteredCustomers : [];
  };

  const handleNextPage = () => {
    setPreviousPage("");
    const invoices = getFilteredCustomers() || [];
    if (!invoices.length) {
      return;
    }
    const lastItem = invoices[invoices.length - 1];
    setNextPage(lastItem.id);
    setPage(page + 1);
    refetch();
  };

  const handlePreviousPage = () => {
    setNextPage("");
    const invoices = getFilteredCustomers() || [];
    if (!invoices.length) {
      return;
    }
    const lastItem = invoices[0];
    setPreviousPage(lastItem.id);
    setPage(page - 1);
    refetch();
  };
  return (
    <Customers
      page={page}
      searchText={searchText}
      customers={allCustomersList?.data}
      hasMore={allCustomersList.has_more}
      setSearchText={setSearchText}
      handleNextPage={handleNextPage}
      handlePreviousPage={handlePreviousPage}
    />
  );
};

export default CustomersContainer;
