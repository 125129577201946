import React, { useState } from "react";
import Joi from "joi-browser";
import { useMutation, useQueryClient } from "react-query";
import { EyeFill, PencilFill, PlusCircle } from "react-bootstrap-icons";
import {
  Alert,
  Button,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import AddCustomerModal from "../../components/Modals/AddCustomer";
import IconButton from "../../components/Button";
import { useHistory } from "react-router-dom";
import StripeElements from "../../components/StripeElements";
import { keys } from "../../query/keys";
import {
  createCustomer,
  updateCustomer,
  searchCustomers,
} from "../../query/queryFunctions";
import { toast } from "react-toastify";
import EditCustomerModal from "../../components/Modals/EditCustomerModal";

const customerValidationSchema = {
  firstName: Joi.string().required().label("First Name"),
  lastName: Joi.string().required().label("Last Name"),
  email: Joi.string().email().required().label("Email"),
  description: Joi.any().optional().label("Note"),
};

const customerUpdateValidationSchema = {
  id: Joi.string(),
  name: Joi.string().required().label("Name"),
  email: Joi.string().email().required().label("Email"),
  description: Joi.any().optional().label("Note"),
};

const Customers = ({
  page,
  hasMore,
  searchText,
  setSearchText,
  customers,
  handleNextPage,
  handlePreviousPage,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState({});
  const [cardError, setCardError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customer, setCustomer] = useState({});
  const [userExistError, setUserExistError] = useState("");
  const [searchType, setSearchType] = useState("name");
  const [searchedCustomers, setSearchedCustomers] = useState([]);
  const [searchMessage, setSearchMessage] = useState("");
  const [isSearching, setIsSearching] = useState("");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const toggleEditModal = (customer) => {
    setUserExistError("");
    setIsEditModalOpen(!isEditModalOpen);
    setCustomer(customer);
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(customer, customerValidationSchema, options);
    if (!error) {
      return null;
    }
    const errors = {};
    for (let item of error?.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const validateUpdateCustomerObject = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(
      customer,
      customerUpdateValidationSchema,
      options
    );
    if (!error) {
      return null;
    }
    const errors = {};
    for (let item of error?.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const validateInputProperty = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: customerUpdateValidationSchema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error?.details[0]?.message : null;
  };

  const validateInputField = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: customerValidationSchema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error?.details[0]?.message : null;
  };

  const mutation = useMutation(createCustomer, {
    onSuccess: (response) => {
      toast.success(response.message);
      queryClient.invalidateQueries(keys.customers);
      setIsModalOpen(!isModalOpen);
      setSearchText("");
      setSearchMessage("");
      setSearchedCustomers([]);
      setCustomer({});
      setUserExistError("");
    },
  });

  const { isLoading: creatingCustomer } = mutation;

  const toggleCustomerModal = () => {
    setCardError("");
    setUserExistError("");
    if (customer) {
      setIsModalOpen(!isModalOpen);
      setCustomer({});
    } else {
      setIsModalOpen(!isModalOpen);
    }
  };

  const handleChange = (key, value) => {
    const _errors = { ...errors };
    let data = { ...customer };
    const errorMessage = validateInputField(key, value);
    if (errorMessage) {
      _errors[key] = errorMessage;
    } else {
      delete _errors[key];
    }

    data[key] = value;
    setErrors(_errors);
    setCustomer(data);
  };

  const handleChangeSelectedCustomer = (key, value) => {
    const _errors = { ...errors };
    let data = { ...customer };
    const errorMessage = validateInputProperty(key, value);
    if (errorMessage) {
      _errors[key] = errorMessage;
    } else {
      delete _errors[key];
    }

    data[key] = value;
    setErrors(_errors);
    setCustomer(data);
  };

  const handleSubmit = (payload) => {
    const isUserExist = customers.find(
      (customer) =>
        customer.name.toLowerCase() === payload.customer.name.toLowerCase() &&
        customer.email.toLowerCase() === payload.customer.email.toLowerCase()
    );
    const invalidObject = validate();
    if (invalidObject) {
      setErrors(invalidObject);
      return;
    }
    if (isUserExist) {
      setUserExistError("Customer Already Exists With Given Email and Name");
      return;
    }

    mutation.mutate(payload);
  };

  const updateMutation = useMutation(updateCustomer, {
    onSuccess: () => {
      toast.success("Customer Updated Successfully!");
      queryClient.invalidateQueries(keys.customers);
      setIsEditModalOpen(!isEditModalOpen);
      setCustomer({});
    },
  });

  const handleUpdate = () => {
    const invalidObject = validateUpdateCustomerObject();
    if (invalidObject) {
      setErrors(invalidObject);
      return;
    }
    const payload = {
      id: customer.id,
      data: {
        name: customer.name,
        email: customer.email,
        description: customer.description,
      },
    };
    updateMutation.mutate(payload);
  };

  const handleSearchCustomers = async (e) => {
    if (!e.target.value || e.target.value.trim() === "") {
      return;
    }
    if (e.key === "Enter") {
      setIsSearching(true);
      setSearchMessage("");
      const res = await searchCustomers({ searchType, value: e.target.value });
      setSearchedCustomers(res?.data);
      setIsSearching(false);
      if (res?.data?.length === 0) {
        setSearchMessage(`No customer found against '${searchText}'`);
      }
      return;
    }
  };

  const handleReset = () => {
    setSearchedCustomers([]);
    setSearchText("");
    setSearchMessage("");
  };

  return (
    <Container className="mt-5">
      {searchedCustomers?.length > 0 && (
        <div className="d-flex align-items-center justify-content-end mb-2">
          <button className="btn btn-primary btn-sm" onClick={handleReset}>
            Reset
          </button>
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div style={{ width: "100%" }}>
          <div className="d-flex" style={{ gap: "5px" }}>
            <div style={{ width: "20%" }}>
              <select
                className="form-control"
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <option value="name">Name</option>
                <option value="email">Email</option>
              </select>
            </div>

            <div style={{ width: "80%" }}>
              <Input
                placeholder="Search customers by name or email and press 'Enter' Key"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={(e) => handleSearchCustomers(e)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-end mb-2">
        <IconButton
          color="primary"
          onClick={toggleCustomerModal}
          title="Add New Customer"
          icon={<PlusCircle className="mr-2" />}
        />
      </div>

      {searchMessage && <div className="alert alert-info">{searchMessage}</div>}
      <Row>
        <Col>
          {isSearching && (
            <div className="text-center mb-2">
              <Spinner type="grow" color="primary" />
            </div>
          )}
          {searchedCustomers?.length > 0 ? (
            <Table responsive hover bordered striped>
              <thead className="thead-dark">
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Note</th>
                  <th colSpan={2}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {searchedCustomers?.length > 0 ? (
                  searchedCustomers?.map((customer, index) => (
                    <tr key={index}>
                      <td>{customer.id}</td>
                      <td>{customer.name}</td>
                      <td>{customer.email}</td>
                      <td>{customer.description}</td>
                      <td className="text-center">
                        <IconButton
                          onClick={() =>
                            history.push(`/customer/${customer.id}`)
                          }
                          icon={<EyeFill />}
                          color="primary"
                          className="btn-sm"
                        />
                      </td>
                      <td className="text-center">
                        <IconButton
                          onClick={() =>
                            toggleEditModal({
                              id: customer.id,
                              name: customer.name,
                              email: customer.email,
                              description: customer.description,
                            })
                          }
                          color="info"
                          icon={<PencilFill />}
                          className="btn-sm"
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <Alert color="info">No Customer's Found</Alert>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <Table responsive hover bordered striped>
              <thead className="thead-dark">
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Note</th>
                  <th colSpan={2}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {customers?.length > 0 ? (
                  customers?.map((customer, index) => (
                    <tr key={index}>
                      <td>{customer.id}</td>
                      <td>{customer.name}</td>
                      <td>{customer.email}</td>
                      <td>{customer.description}</td>
                      <td className="text-center">
                        <IconButton
                          onClick={() =>
                            history.push(`/customer/${customer.id}`)
                          }
                          icon={<EyeFill />}
                          color="primary"
                          className="btn-sm"
                        />
                      </td>
                      <td className="text-center">
                        <IconButton
                          onClick={() =>
                            toggleEditModal({
                              id: customer.id,
                              name: customer.name,
                              email: customer.email,
                              description: customer.description,
                            })
                          }
                          color="info"
                          icon={<PencilFill />}
                          className="btn-sm"
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <Alert color="info">No Customer's Found</Alert>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "5px",
              marginBottom: "5px",
            }}
          >
            <Button
              color="primary"
              onClick={handlePreviousPage}
              disabled={page === 1}
            >
              Previous
            </Button>
            <Button
              color="primary"
              onClick={handleNextPage}
              disabled={!hasMore && page !== 1}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>

      <StripeElements>
        <AddCustomerModal
          isOpen={isModalOpen}
          customer={customer}
          toggle={toggleCustomerModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          creatingCustomer={creatingCustomer}
          userExistError={userExistError}
          errors={errors}
          cardError={cardError}
          setCardError={setCardError}
        />
      </StripeElements>

      <EditCustomerModal
        errors={errors}
        customer={customer}
        isOpen={isEditModalOpen}
        toggle={toggleEditModal}
        handleUpdate={handleUpdate}
        isLoading={updateMutation.isLoading}
        handleChange={handleChangeSelectedCustomer}
      />
    </Container>
  );
};

export default Customers;
