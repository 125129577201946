import React, { useState } from "react";
import { XCircle, ClockFill } from "react-bootstrap-icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Alert, Col, Row, Spinner, Table, Input, Button } from "reactstrap";
import IconButton from "../../components/Button";
import InvoiceCancel from "../../components/Modals/InvoiceCancel";
import InvoiceUpdateModal from "../../components/Modals/InvoiceUpdate";
import { keys } from "../../query/keys";
import {
  cancelInvoice,
  getClinicInvoices,
  sendReminderEmail,
} from "../../query/queryFunctions";
import {
  amountWithcommas,
  capitalizeFirstLetterOfString,
} from "../../utils/helpers";

const ClinicInvoices = () => {
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInvoiceUpdateModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [status, setStatus] = useState("overdue");
  const [nextPage, setNextPage] = useState("");
  const [previousPage, setPreviousPage] = useState("");
  const [page, setPage] = useState(1);

  const [invoiceData, setInvoiceData] = useState({});

  const toggleInvoiceUpdateModal = (invoice) => {
    if (invoice) {
      setInvoiceData(invoice);
      setIsInvoiceModalOpen(!isInvoiceUpdateModalOpen);
    } else {
      setIsInvoiceModalOpen(!isInvoiceUpdateModalOpen);
    }
  };

  const toggleModal = (id) => {
    if (id) {
      setInvoiceId(id);
      setIsModalOpen(!isModalOpen);
    } else {
      setIsModalOpen(!isModalOpen);
    }
  };

  const {
    isLoading,
    refetch,
    data: invoices,
  } = useQuery(
    [keys.clinicInvoices, nextPage, previousPage],
    () => getClinicInvoices({ nextPage, previousPage }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const reminderMutation = useMutation(sendReminderEmail, {
    onSuccess: (response) => {
      toast.success(response?.message);
      console.log(response);
    },
  });

  const mutation = useMutation(cancelInvoice, {
    onSuccess: (response) => {
      toast.success(response.message);
      queryClient.invalidateQueries(keys.clinicInvoices);
      setInvoiceId("");
      setIsModalOpen(!isModalOpen);
    },
  });

  const handleCancelInvoice = () => {
    mutation.mutate(invoiceId);
  };

  if (isLoading) {
    return (
      <div className="text-center mt-5">
        <Spinner color="primary" />
      </div>
    );
  }

  const getInvoiceTotalValue = (invoices) => {
    let totalPrice = invoices?.reduce(function (accumulator, item) {
      return accumulator + item.total;
    }, 0);
    return totalPrice;
  };

  const getInvoicesByFilter = () => {
    let _invoices = [];
    if (status === "overdue") {
      _invoices = invoices?.data?.filter(
        (invoice) =>
          new Date(invoice?.due_date * 1000) < Date.now() &&
          !["paid", "draft", "void"].includes(invoice.status)
      );
      return _invoices;
    }
    _invoices = invoices?.data?.filter((invoice) => invoice.status === status);
    return status === "all" ? invoices?.data : _invoices;
  };

  const handleSendReminderEmail = (invoice) => {
    reminderMutation.mutate(invoice);
  };

  const handleNextPage = () => {
    setPreviousPage("");
    const invoices = getInvoicesByFilter() || [];
    if (!invoices.length) {
      return;
    }
    const lastItem = invoices[invoices.length - 1];
    setNextPage(lastItem.id);
    setPage(page + 1);
    refetch();
  };

  const handlePreviousPage = () => {
    setNextPage("");
    const invoices = getInvoicesByFilter() || [];
    if (!invoices.length) {
      return;
    }
    const lastItem = invoices[0];
    setPreviousPage(lastItem.id);
    setPage(page - 1);
    refetch();
  };

  return (
    <div className="p-4 mt-5">
      <Row>
        <Col md={12}>
          {invoices?.data?.length > 0 && (
            <Row form className="mb-3">
              <Col md={4}>
                <Input
                  type="select"
                  size="lg"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option selected disabled value="">
                    Choose an option to filter invoice's
                  </option>
                  <option value="all">All</option>
                  <option value="draft">Draft</option>
                  <option value="open">Open</option>
                  <option value="paid">Paid</option>
                  <option value="void">Void</option>
                  <option value="overdue">Overdue</option>
                </Input>
              </Col>
              <Col md={6}>
                <Alert color={status === "overdue" ? "danger" : "info"}>
                  All {status !== "all" && status} Invoices (on this page) -
                  <b>
                    $
                    {amountWithcommas(
                      getInvoiceTotalValue(getInvoicesByFilter()) / 100
                    )}
                  </b>
                </Alert>
              </Col>
            </Row>
          )}

          <Table responsive bordered hover>
            <thead>
              <tr className="text-center">
                <th>Invoice #</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Customer</th>
                <th>Created Date</th>
                <th>Due Date</th>
                <th>Note</th>
                <th>Paid Status</th>
                <th colSpan={3}>Action</th>
              </tr>
            </thead>

            <tbody>
              {getInvoicesByFilter()?.length > 0 ? (
                getInvoicesByFilter()?.map((invoice, index) => (
                  <tr key={index}>
                    <td>{invoice?.number}</td>
                    <td className="text-center">
                      {invoice?.payment_intent?.slice(21)}
                    </td>
                    <td className="text-center">${invoice?.total / 100}</td>
                    <td className="text-center">
                      {capitalizeFirstLetterOfString(invoice?.status)}
                    </td>
                    <td>{invoice?.customer_email}</td>
                    <td>
                      {new Date(invoice?.created * 1000).toLocaleDateString()}
                    </td>
                    <td>
                      {new Date(invoice?.due_date * 1000).toLocaleDateString()}
                    </td>
                    <td>{invoice?.description}</td>
                    <td>
                      {invoice?.status === "void" ? (
                        <span className="badge badge-danger">Void</span>
                      ) : new Date(invoice?.due_date * 1000) < Date.now() &&
                        invoice?.status !== "paid" ? (
                        <span className="badge badge-danger">Overdue</span>
                      ) : new Date(invoice?.due_date * 1000) > Date.now() &&
                        invoice?.status !== "paid" ? (
                        <span className="badge badge-warning">Pending</span>
                      ) : (
                        <span className="badge badge-success">Paid</span>
                      )}
                    </td>

                    <td className="text-center">
                      <IconButton
                        title="Reminder"
                        color="primary"
                        size="sm"
                        disabled={
                          ["draft", "void", "paid"].includes(invoice.status) ||
                          reminderMutation.isLoading
                        }
                        icon={<ClockFill className="mr-2" />}
                        onClick={() => handleSendReminderEmail(invoice)}
                      />
                    </td>
                    <td>
                      <IconButton
                        title="Cancel"
                        color="danger"
                        size="sm"
                        disabled={["draft", "paid", "void"].includes(
                          invoice?.status
                        )}
                        icon={<XCircle className="mr-2" />}
                        onClick={() => toggleModal(invoice?.id)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10}>
                    <Alert color="info">
                      No Invoice history found for that customer
                    </Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "5px",
              marginBottom: "5px",
            }}
          >
            <Button
              color="primary"
              onClick={handlePreviousPage}
              disabled={page === 1}
            >
              Previous
            </Button>
            <Button
              color="primary"
              onClick={handleNextPage}
              disabled={!invoices.has_more && page !== 1}
            >
              Next
            </Button>
          </div>
        </Col>

        <InvoiceCancel
          isOpen={isModalOpen}
          toggle={toggleModal}
          handleSubmit={handleCancelInvoice}
          isLoading={mutation.isLoading}
          isError={mutation?.isError}
          error={mutation?.error?.message}
        />

        <InvoiceUpdateModal
          isOpen={isInvoiceUpdateModalOpen}
          toggle={toggleInvoiceUpdateModal}
          invoice={invoiceData}
          handleSubmit={handleCancelInvoice}
          isLoading={mutation.isLoading}
          isError={mutation?.isError}
          error={mutation?.error?.message}
        />
      </Row>
    </div>
  );
};

export default ClinicInvoices;
