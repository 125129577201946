import React, { useState } from "react";
import Joi from "joi-browser";
import { useMutation, useQueryClient } from "react-query";
import {
  XCircle,
  CloudArrowDownFill,
  PlusCircle,
  Trash,
} from "react-bootstrap-icons";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Alert,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import IconButton from "../Button";
import { createInvoice, uploadFile } from "../../query/queryFunctions";
import "react-datepicker/dist/react-datepicker.css";
import { keys } from "../../query/keys";
import { useAuthContext } from "../../context/authContext";

const customerValidationSchema = {
  name: Joi.string().required().label("Name"),
  email: Joi.string().required().email().label("Email"),
  dueDate: Joi.string().required().label("Due Date"),
};

const invoiceItemValidationSchema = {
  item: Joi.string().required().label("Item Name"),
  price: Joi.number().min(1).positive().required().label("Price"),
};

const InvoiceModal = ({ isOpen, toggle, customer: stripeCustomer }) => {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [invoiceData, setInvoiceData] = useState({
    dueDate: new Date().setDate(new Date().getDate() + 1),
    description: "",
  });
  const [fileUrl, setFileUrl] = useState("");
  const [fileError, setFileError] = useState("");
  const [itemErrors, setItemErrors] = useState([]);
  const [errors, setErrors] = useState({});
  const [customer, setCustomer] = useState({
    name: stripeCustomer?.customer?.name,
    email: stripeCustomer?.customer?.email,
  });

  const [items, setItems] = useState([
    {
      item: "",
      quantity: 1,
      price: "",
    },
  ]);

  const [invoiceError, setInvoiceError] = useState("");
  const handleToggle = () => {
    setInvoiceError("");
    queryClient.invalidateQueries(keys.invoices(stripeCustomer?.customer?.id));
    toggle();
    setInvoiceData({
      dueDate: new Date().setDate(new Date().getDate() + 1),
      description: "",
    });
    setFileUrl("");
    setFileError("");
    setItemErrors([]);
    setErrors({});
    setItems([
      {
        item: "",
        quantity: 1,
        price: "",
      },
    ]);
  };

  const handleAddItem = () => {
    const data = {
      item: "",
      quantity: 1,
      price: "",
    };
    setItems([...items, data]);
  };

  const handleRemoveItem = (index) => {
    let data = [...items];
    data.splice(index, 1);
    setItems(data);
  };

  const validateItemsInputFields = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: invoiceItemValidationSchema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error?.details[0]?.message : null;
  };

  const handleChange = (key, value, index) => {
    let fieldErrors = { ...itemErrors };
    let data = [...items];
    let selectedItem = { ...data[index] };
    const errorMessage = validateItemsInputFields(key, value);
    if (fieldErrors) {
      fieldErrors[index] = { [key]: errorMessage };
    } else {
      delete fieldErrors[index][key];
    }
    selectedItem[key] = value;
    data[index] = selectedItem;
    setItems(data);
    setItemErrors(fieldErrors);
  };

  const validateCustomerInputFields = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: customerValidationSchema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error?.details[0]?.message : null;
  };

  const handleCustomerChange = (key, value) => {
    const fieldErrors = { ...errors };
    let data = { ...customer };
    const errorMessage = validateCustomerInputFields(key, value);
    if (fieldErrors) {
      fieldErrors[key] = errorMessage;
    } else {
      delete fieldErrors[key];
    }

    data[key] = value;
    setCustomer(data);
    setErrors(fieldErrors);
  };

  const mutation = useMutation(createInvoice, {
    onSuccess: (response) => {
      toast.success(response?.message);
      queryClient.invalidateQueries(
        keys.invoices(stripeCustomer?.customer?.id)
      );
      handleToggle();
    },
    onError: (error) => {
      setInvoiceError(error?.message);
    },
  });

  const handleSubmit = () => {
    const payload = {
      customer: stripeCustomer?.customer?.id,
      products: items,
      productName: items[0]?.item,
      productPrice: items[0]?.price,
      dueDate: invoiceData.dueDate,
      description: invoiceData.description,
      brand: user?.data?.brand,
      items,
    };

    if (fileUrl) {
      payload.invoicePdf = fileUrl;
    }
    mutation.mutate(payload);
  };

  const handleChangeInvoiceData = (key, value) => {
    let data = { ...invoiceData };
    data[key] = value;
    setInvoiceData(data);
  };

  const fileMutation = useMutation(uploadFile, {
    onSuccess: (response) => {
      toast.success(response?.message);
      setFileUrl(response?.url);
    },
    onError: (error) => {
      setFileError(error?.message);
      toast.error("File is not uploaded, please try again");
    },
  });

  const handleUploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    fileMutation.mutate(formData);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} backdrop="static" size="lg">
      <ModalHeader toggle={handleToggle}>Send an Invoice</ModalHeader>
      <ModalBody>
        {invoiceError && <Alert color="danger">{invoiceError}</Alert>}
        <h4>Customer</h4>
        <Row form className="mt-3">
          <Col md={4}>
            <FormGroup>
              <Label>Name</Label>
              <Input
                disabled
                value={customer.name}
                onChange={(e) => handleCustomerChange("name", e.target.value)}
                placeholder="Enter the Name of the customer"
              />
              {errors?.name && (
                <div className="text text-danger mt-1">{errors?.name}</div>
              )}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                disabled
                type="email"
                value={customer.email}
                onChange={(e) => handleCustomerChange("email", e.target.value)}
                placeholder="Enter the valid Email Address"
              />
              {errors?.email && (
                <div className="text text-danger mt-1">{errors?.email}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label>Due Date</Label>
              <DatePicker
                minDate={new Date().setDate(new Date().getDate() + 1)}
                selected={invoiceData.dueDate}
                className="form-control"
                onChange={(date) => handleChangeInvoiceData("dueDate", date)}
              />
              {errors?.dueDate && (
                <div className="text text-danger mt-1">{errors?.dueDate}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label>Description</Label>
              <Input
                type="textarea"
                rows={3}
                value={invoiceData.description}
                onChange={(e) =>
                  handleChangeInvoiceData("description", e.target.value)
                }
                maxLength={250}
              />
              <small className="float-right mt-1 text-muted">
                {invoiceData?.description?.length || 0}/250
              </small>
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label>Upload PDF File</Label>
              <Input
                type="file"
                accept="application/pdf"
                onChange={(e) => handleUploadFile(e.target.files[0])}
              />
            </FormGroup>
          </Col>

          {fileMutation?.isError && <Alert color="danger">{fileError}</Alert>}
          {fileUrl && <Alert color="success">{fileUrl}</Alert>}
        </Row>

        <h4>Invoice Items</h4>
        {items?.map((item, index) => (
          <Row form className="mt-2" key={index}>
            <Col md={4}>
              <FormGroup>
                <Label>Item Name</Label>
                <Input
                  required
                  value={items[index].item}
                  onChange={(e) => handleChange("item", e.target.value, index)}
                  placeholder="Enter the item name"
                />
                {itemErrors[index]?.item && (
                  <div className="text text-danger mt-1">
                    {itemErrors[index]?.item}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Quantity</Label>
                <Input
                  required
                  placeholder="Enter the item name"
                  value={items[index].quantity}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Price</Label>
                <Input
                  required
                  type="number"
                  placeholder="00.00"
                  value={items[index].price}
                  onChange={(e) => handleChange("price", e.target.value, index)}
                />
                {itemErrors[index]?.price && (
                  <div className="text text-danger mt-1">
                    {itemErrors[index]?.price}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md={2} style={{ marginTop: "35px" }}>
              <IconButton
                icon={<Trash />}
                color="danger"
                onClick={() => handleRemoveItem(index)}
                disabled={items.length === 1}
              />
            </Col>
          </Row>
        ))}

        <IconButton
          color="primary"
          title="Add Item"
          disabled={mutation.isLoading || fileMutation.isLoading}
          icon={<PlusCircle className="mr-2" />}
          onClick={handleAddItem}
        />
      </ModalBody>
      <ModalFooter>
        <IconButton
          onClick={handleToggle}
          disabled={mutation.isLoading || fileMutation.isLoading}
          title="Close"
          icon={<XCircle className="mr-2" />}
        />
        <IconButton
          color="primary"
          title="SEND"
          icon={<CloudArrowDownFill className="mr-2" />}
          disabled={mutation.isLoading || fileMutation.isLoading}
          onClick={handleSubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default InvoiceModal;
